.c-label{
  border-radius: 25px;
  border: 1px solid #dedede60;
  font-size: 12px !important;
  padding: 2px 8px;
}


.c-label:hover{
  border-color: #dedede;
}


.case-detail-meta-pane.expanded {
  min-width: 340px;
}

.case-detail-meta-pane {
  transition: all 0.3s ease-in-out;
  min-width: 0;
  max-width: 0;
  overflow: hidden;
}

.c-modal{
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-modal > .modal-content-wrapper{
  background-color: white;
  width: 100%;
  max-width: 75vw;
  margin: 0 auto;
  overflow: auto;
  border-radius: 5px;
  outline: none;
}

.c-modal.article-version-modal .modal-content-wrapper{
  height:100%;
  max-height: 80vh;
}


.c-modal.auto-height{
  padding-top: 8rem;
}

.c-modal-body-wrapper{
  position: relative;
}
.c-modal-header{
  border-bottom: 1px solid #dedede40;
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 10;
}
.c-modal-header .c-modal-header{
  border-bottom: none;
}

.c-modal-body .c-modal-body{
  padding: 0;
  background-color: white;
}

.c-modal-footer .c-modal-footer{
  background-color: white;
  border-top: 1px solid #dedede40;
}


.react-select-add-button {
  /* background-color: #fff; */
  background-color: #eff4f6;
  border: 1px double #eee;
  /* color: rgb(41, 146, 208); */
  color: #1e3a8a;
  padding: 10px;
  display: flex;
  align-items: cener;
  font-size: 13px;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: none;
  border-left: none;
  border-bottom: none;
}


.delete-modal .c-modal-header {
  background-color: rgb(247, 94, 94);
  color: white;
}

.dialog-modal .c-modal-header {
  background-color: #1e3a8a;
  color: white;
}

.delete-modal .c-modal-body{
  background-color: white;
}
.delete-modal .c-modal-body,
.dialog-modal .c-modal-body {
  border-color: transparent !important;
}

.delete-modal button:first-child {
  background-color: rgb(247, 94, 94) !important;
  color: white;
}

.dialog-modal button:first-child {
  background-color: #1e3a8a !important;
  color: white;
}