@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;

@tailwind components;

@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

:root {
  --primary: #1976d3;
  --primary-accent: #edf5ff;
  --background: #fafafa;
  --white: #ffffff;
  --success: #66bb6a;
  --success-accent: #defbe6;
}

.text-primary {
  color: #435883;
}

.text-secondary {
  color: #64748b;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: "Montserrat", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a.custom-link {
  color: var(--primary) !important;
  word-break: break-all;
  text-decoration: underline !important;
}

.logoimage {
  height: 30px;
}
.branchesLogoimage {
  width: 200px;
}

.whiteLogo {
  width: 280px;
}

.navHeader {
  background-color: #ffffff !important;
  color: azure !important;
  padding: 5px;
}

.headerNavLink {
  color: black !important;
  text-transform: none !important;
  padding: 0px 10px !important;
  font-weight: 600 !important;
}
.NavMenu {
  color: black !important;
  text-transform: none !important;
  padding: 0px 10px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

/* error message in form validation  */
.error {
  color: #eb2428;
  font-size: 12px;
}

.MuiDrawer-paperAnchorDockedLeft {
  border: none !important;
}

.expanded-form {
  background-color: var(--primary-accent);
  box-shadow: inset 0 0 10px lightgray;
}

.MuiBox-root > a {
  color: inherit;
  text-decoration: none;
}

/* next action  */
.due-next-action {
  border: 1px solid #ef4444;
  background-color: #fee2e2 !important;
}

.due-today {
  border: 1px solid var(--primary);
  background-color: var(--primary-accent) !important;
}

.fs-14 {
  font-size: 14px !important;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-font {
  font-size: 0.8125rem;
}

.MuiChip-labelSmall {
  font-size: 14px !important;
}
.testimonials-text {
  font-size: 14px !important;
  margin-bottom: 1rem !important;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

/* .css-1aquho2-MuiTabs-indicator {
  background: #eb2428 !important;
} */
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background: #fff !important;
  color: #000 !important;
}
.slick-dots {
  top: 100px !important;
  left: 100px !important;
}
.slick-dots li.slick-active button:before {
  color: #eb2428 !important;
}
