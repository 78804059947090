@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

html * {
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px;
}
h1 {
  font-size: 38px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 32px;
}
h4 {
  font-size: 28px;
}
h5 {
  font-size: 25px;
}
h6 {
  font-size: 22px;
}

.aboutus-box {
  /* border: 2px solid black; */
  margin: 30px 0px;
  box-shadow: 3px 4px 4px 2px grey;
}

blockquote {
  font-family: "Montserrat", sans-serif !important;
  position: relative;
  margin: 0.5em;
  padding: 0.5em 2em 0.5em 3em;
}
blockquote:before {
  font-family: "Montserrat", sans-serif !important;
  position: absolute;
  font-size: 3em;
  line-height: 1;
  top: 0;
  left: 25px;
  content: "\201C";
}
blockquote:after {
  font-family: "Montserrat", sans-serif !important;
  position: absolute;
  float: right;
  font-size: 3em;
  line-height: 1;
  bottom: -10px;
  content: "\201D";
}

.WhiteContainer {
  margin: 60px 10px;
}

.OurPartnerCardContent {
  width: 200px;
}
.contactustext {
  margin-bottom: 0px !important;
}
.ContactUsFormBox {
  box-shadow: 2px 4px 29px rgba(0, 0, 0, 0.25);
  margin: 2rem;
  border-radius: 5px !important;
}

.ContactSubmitBtn {
  background: radial-gradient(
    100% 372.58% at 0% 51.16%,
    #94001e 0%,
    #eb2428 100%
  );
  border-radius: 50px !important;
  text-transform: none !important;
  padding: 0.3rem 2rem !important;
}
.ContactSubmitBtn:hover {
  background: radial-gradient(
    110.62% 3146.67% at 104.38% 50%,
    #94001e 0%,
    #eb2428 100%
  );
}

/* top div of test component */
.test-topdiv,
.test-bottomdiv {
  margin-top: 100px;
  position: relative;
  height: 400px;
}
.img-top-test {
  position: absolute;
  top: 0;
  left: 0;
}
.content-top-test {
  position: absolute;
  margin-left: 35% !important;
  margin-top: -3% !important;
}
.content-bot-test {
  position: absolute;
  margin-left: 32% !important;
  margin-top: 2% !important;
}

.img-bottom-test {
  position: absolute;
  margin-left: 43% !important;
}
.content-bottom-test {
  position: absolute;
  left: 0;
  margin-top: -3% !important;
  z-index: 30;
}
.VisionImage {
  height: auto;
  width: 70%;
  border: 1rem solid #fff;
}

@media only screen and (max-width: 600px) {
  html * {
    overflow-x: hidden;
  }
  .GrayContainer {
    padding: 40px 10px !important;
  }

  .whiteBackground {
    padding: 40px 10px !important;
  }
  blockquote {
    font-family: "Montserrat", sans-serif !important;
    position: relative;
    margin: 0.5em;
    padding: 0.5em 2em 0.5em 3em;
  }
  blockquote:before {
    font-family: "Montserrat", sans-serif !important;
    position: absolute;
    font-size: 2em;
    line-height: 1;
    top: 0;
    left: 25px;
    content: "\201C";
  }
  blockquote:after {
    font-family: "Montserrat", sans-serif !important;
    position: absolute;
    float: right;
    font-size: 2em;
    line-height: 1;
    bottom: 0px;
    content: "\201D";
  }
  .test-topdiv,
  .test-bottomdiv {
    position: relative;
    height: 630px;
  }
  .img-top-test {
    position: inherit;
    top: 0;
    left: 0;
  }
  .content-top-test {
    position: inherit;
    margin-left: 0% !important;
    margin-top: 0% !important;
  }
  .content-bot-test {
    position: inherit;
    margin-left: 0% !important;
    margin-top: 0% !important;
  }

  .img-bottom-test {
    position: inherit;
    margin-left: 0% !important;
    margin-top: 2% !important;
  }
  .content-bottom-test {
    position: inherit;
    left: 0;
    z-index: 0;
  }
  .VisionImage {
    height: auto;
    width: 91%;
    border: 1rem solid #fff;
  }
}
@media only screen and (min-width: 1024px) {
  .test-content-box {
    height: 280px;
    margin-top: 10%;
  }
  .test-content {
    padding: 40px 40px !important;
  }
  .bot-content-box {
    height: 250px;
  }
  .bot-content {
    padding: 40px 40px !important;
  }
  .countryCard {
    width: 1100px !important;
    box-shadow: -2px -3px 15px 5px #aaaaaa, 2px 3px 15px 5px #aaaaaa,
      5px -3px 15px 5px #aaaaaa !important;
  }
}
.aboutUsQuote {
  font-family: "Montserrat", sans-serif !important;
}

.css-r40f8v-MuiTypography-root {
  font-size: 16px !important;
}

.testimonials-text {
  font-size: 14px !important;
  margin-bottom: 2rem;
  min-height: 200px;
}
