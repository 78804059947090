h1 {
  color: #eb2428;
}
.redColor {
  color: #eb2428;
}
.ChooseDestiation {
  color: #eb2428;
  padding-top: 0px;
  margin-top: 0px;
}
.StudyAbroadH1 {
  color: #eb2428;
  margin-bottom: 0px !important;
  font-weight: 600;
}
.BannerContainer {
  position: relative;
  color: #fff;
}
.BannerImage {
  object-fit: cover;
  background-color: #fff;
  width: 100%;
}
.CarouselContainer {
  object-fit: cover;
}
.slick-next:before,
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  color: #eb2428;
}
.BannerTitle {
  position: absolute;
  top: 27%;
  left: 5%;
  font-size: 35px;
  font-weight: 600;
  width: 35%;
  line-height: 2.5rem;
}

.BannerDescription {
  font-size: 18px;
  font-weight: lighter;
  position: absolute;
  top: 45%;
  left: 5%;
  width: 23%;
  margin-bottom: 3rem;
}
.CircleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 999;
}
.step4Background {
  background-color: #e7e7e7;
  padding: 1rem 0 3rem 0;
}
.stepText {
  font-size: 40px !important;
  z-index: 2;
}
.whyFSWIcons {
  background-color: #eb2428;
  border-radius: 50%;
  color: #edf5ff;
  width: 120px;
  height: 120px;
  display: grid;
  place-content: center;
  margin-bottom: 1rem;
}
.ApplicationProcessIcons {
  background-color: #eb2428;
  border-radius: 50%;
  color: #edf5ff;
  width: 80px;
  height: 80px;
  display: grid;
  place-content: center;
  /* margin-bottom: 1rem; */
  z-index: 9;
}
.stepIcons {
  background-color: #eb2428;
  border-radius: 50%;
  color: #edf5ff;
  width: 80px;
  height: 80px;
  display: grid;
  place-content: center;
}
.whyFSWIconBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 2rem;
  position: relative;
}

.step4Box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 2rem;
  position: relative;
}

.step4Box::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #eb2428;
  z-index: 1;
}

.step4Text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.CountryScreenBannerContainer {
  position: relative;
  text-align: center;
  color: #fff;
}

.bannerBackgroundImage {
  width: 100%;
  height: auto;
}

.meetTheTeamBanner {
  width: 100%;
  height: 350px;
  background-color: #000;
  justify-content: center !important;
  align-items: center;
}

.banner-background-filter::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background: #000;
}
.banner-background-filter {
  position: relative;
}

.CountryScreenBannerTitle {
  position: absolute;
  top: 40%;
  left: 40%;
  font-size: 50px !important;
  font-weight: 800 !important;
  color: #fff;
}

.TesimonialsCard {
  padding-right: 10px;
  margin-left: 10px;
  min-height: 380px;
}

.testimonials-text {
  font-size: 14px !important;
  /* margin-bottom: 2rem; */
}

.AboutUs1 {
  position: relative;
}
.AboutUs1-1 {
  position: absolute;
  top: 24%;
  left: 33%;
}

.BannerBtn {
  font-weight: 600;
  font-size: 18px;
  color: #fff !important;
  text-decoration: none !important;
  padding: 0.5rem 2rem;
  position: absolute;
  top: 55%;
  left: 5%;
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
  display: inline-block;
  background: radial-gradient(
    110.62% 3146.67% at 104.38% 50%,
    #eb2428 0%,
    #94001e 100%
  );
  border-radius: 25px;
  margin-top: 1rem;
}
.BannerBtn:hover {
  background: radial-gradient(
    110.62% 3146.67% at 104.38% 50%,
    #94001e 0%,
    #eb2428 100%
  );
}
.AboutUsStudentImage {
  height: auto;
  width: 80%;
  border: 1rem solid #fff;
}
.AboutUsContantBox {
  margin-right: 500px;
}
.expertImage {
  width: 100%;
  height: auto;
}

.StudyAbroadImage {
  background-image: url(../screens/assets/images/australia.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: block;
  opacity: 0.8;
  transition: opacity 1s, 1s;
}
.StudyAbroadTint {
  background-color: rgba(0, 0, 0, 0.81); /* Tint color */
}

.whyFswSection {
  background-color: #000;
  padding: 3rem 0;
}
.branchesLogoImage {
  width: 250px;
}

.aboutUsContainer {
  padding: 2rem;
}

.FooterContainer {
  background-image: url(../screens/assets/images/footerImage.png);
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
}
.AboutUsContainer {
  background-color: #f9f9f9;
  padding: 2rem;
}
.GrayContainer {
  background-color: #eee;
  padding: 1rem 0rem 3rem 0rem;
  margin: 0rem;
}
.CarouselContainer {
  background-color: #fff;
}
.WhiteContainer {
  margin-bottom: 3rem;
}
.ServicesIcons {
  color: #5a5a5a;
  font-size: 50px !important;
}
.ServicesIcons:hover {
  color: #eb2428;
  font-size: 65px !important;
}
.serviceCard {
  height: 320px;
  /* margin: 20px 70px; */
  /* padding: 50px 0px; */
}

/* .OurServicesCardContent:hover {
  background-color: #eb2428 !important;
} */

.WhatWeDoCard {
  box-shadow: -8px -9px 15px 5px #aaaaaa, 8px 9px 15px 5px #aaaaaa,
    20px -9px 15px 5px #aaaaaa !important;
  font-size: 16px !important;
}
.WhoWeAreCard {
  box-shadow: 10px 10px 5px #aaaaaa !important;
  font-size: 16px !important;
}
.GlobalBranchContainer {
  margin-bottom: 1rem;
  margin-top: 2rem !important;
}
.StudyAbroadCarouselImage {
  width: 300px;
  background-color: #00000050;
  z-index: 100;
  position: absolute;
  height: 150px;
  top: 0;
  border-radius: 10px;
}
.SocialIcons {
  font-size: 35px !important;
  padding-right: 0.5rem;
  color: #eee !important;
}
.SocialIcons:hover {
  color: #eb2428 !important;
}

.carousel-wrapper {
  margin-top: 2%;
  margin-bottom: 2%;
}
.StudyAbroadCarouselImage > p {
  position: absolute;
  top: 45%;
  left: 40%;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
}
.UniversityLogoes {
  width: 190px;
  height: auto;
  padding: 20px 0px;
}
.GlobalBranchesDescription {
  font-size: 16px;
}
.ConsultExpertContainer {
  padding-bottom: 25px;
  color: #eee;
  background: radial-gradient(
    126.22% 5661.87% at -8.65% -8.84%,
    #94001e 47.87%,
    #eb2428 91.79%
  );
}
.rec-item-wrapper {
  margin-top: 0px;
  padding-top: 0px;
}
.ConsultExpertH1 {
  color: #eee;
}
.SuscribeField {
  border: 1px solid #eee !important;
  border-right: 0px !important;
  border-radius: 0px !important;
  height: 2.5rem !important;
  margin-bottom: 1.5rem;
}
.SubscribeBtn {
  border: 1px solid #eee !important;
  border-left: 0px !important;
  border-radius: 0px 30px 30px 0px !important;
  height: 2.5rem !important;
  text-transform: capitalize !important;
  font-weight: 500 !important;
  background-color: #eb2428 !important;
  color: #eee !important;
}
.SubscribeBtn:hover {
  background: radial-gradient(
    110.62% 3146.67% at 104.38% 50%,
    #94001e 0%,
    #eb2428 100%
  );
}
.SuscribeField :placeholder-shown {
  color: #eee !important;
  font-weight: 400 !important;
}
.FooterLinks {
  font-size: 14px;
  color: #eee !important;
  padding: 0px 0px 5px 0px !important;
}
.FooterItemLinks:hover {
  color: #eb2428 !important;
}
.BannerContainer::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background: #000;
}
.background-filter::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: #000;
}
.background-filter {
  position: relative;
}
.background {
  background-image: url("../screens/assets/images/footerImage.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.ExpertContactBtn {
  border: 2px solid #fff !important;
  color: #fff !important;
  padding: 0.5rem !important;
  font-size: 16px !important;
}
.ExpertContactBtn:hover {
  background: radial-gradient(
    110.62% 3146.67% at 104.38% 50%,
    #eb2428 0%,
    #94001e 100%
  );
}
.FooterSmallText {
  font-size: 13px !important;
  color: #eee;
  text-decoration: none;
}
.BottomFooter {
  background-color: rgb(36, 35, 35);
  padding: 0.75rem;
  border-top: 1px solid #fff;
}
.BottomFooterText {
  color: #eee;
  font-size: 12px !important;
  font-weight: lighter;
}
.footernepalcan {
  color: #eb2428 !important;
  text-decoration: none !important;
}
/* top div of aboutus component */
.aboutus-topdiv,
.aboutus-bottomdiv {
  position: relative;
  height: 340px;
}
.img-top-aboutus {
  position: absolute;
  top: 0;
  left: 0;
}
.content-top-aboutus {
  position: absolute;
  margin-left: 35% !important;
  margin-top: 5% !important;
}

.img-bottom-aboutus {
  position: absolute;
  margin-left: 43% !important;
}
.content-bottom-aboutus {
  position: absolute;
  left: 0;
  margin-top: 5% !important;
  z-index: 30;
}

.teamsImageEnd {
  display: flex;
  justify-content: flex-end;
}

.TeamsTabBtn {
  color: #fff !important;
  font-weight: 800 !important;
  margin-right: 15px !important;
}

.meetTheTeamHeader {
  font-size: 4rem !important;
  font-weight: 600 !important;
  color: #fff;
  /* margin-bottom: 3rem !important; */
  margin-top: 7rem !important;
}
.meetTheTeamText {
  font-size: 4rem !important;
  font-weight: 600 !important;
  color: #eb2428;
}
.ourStoryHeading {
  font-size: 3rem !important;
  font-weight: 600 !important;
  color: #000000;
}
.whyFSWIconsText {
  font-size: 70px !important;
}

@media only screen and (max-width: 600px) {
  .CircleContainer {
    display: table-row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 999;
  }

  .stepIcons {
    width: 50px;
    height: 50px;
  }
  .stepText {
    font-size: 20px !important;
  }

  .meetTheTeamHeader {
    font-size: 2rem !important;
    font-weight: 400 !important;
    margin: 5rem 0 2rem 2rem !important;
  }

  .meetTheTeamText {
    font-size: 2rem !important;
    font-weight: 600 !important;
  }

  .TeamsTabBtn {
    font-weight: 500 !important;
    margin: 0px !important;
    padding: 0px !important;
    font-size: 0.6rem !important;
    min-width: 70px !important;
  }

  .rightTeamImage {
    order: 0 !important;
  }
  .teamsImageEnd {
    display: flex !important;
    justify-content: flex-start !important;
  }
  .teamsImageStart {
    display: flex !important;
    justify-content: flex-start !important;
  }
  .CountryScreenBannerTitle {
    top: 12%;
    left: 17%;
    font-size: 25px !important;
    font-weight: 700 !important;
  }

  .BannerTitle {
    position: absolute;
    top: 20%;
    left: 5%;
    font-size: 20px;
    font-weight: 600;
    width: 80%;
    line-height: 1.5rem;
  }

  .BannerDescription {
    font-size: 13px;
    font-weight: 300;
    position: absolute;
    top: 40%;
    left: 5%;
    width: 80%;
    margin-bottom: 1rem;
  }
  .GlobalBranchContainer {
    margin-bottom: 2rem;
  }

  .StudyAbroadH1 {
    color: #eb2428;
    margin-bottom: 0px !important;
    font-weight: 600;
    font-size: 28px;
  }

  .h4Text {
    font-size: 24px;
  }

  .h1Text {
    font-size: 28px;
  }

  .aboutUsContainer {
    padding: 2rem;
  }

  .ConsultExpertContainer {
    padding-top: 2rem;
    color: #eee;
    background: radial-gradient(
      126.22% 5661.87% at -8.65% -8.84%,
      #94001e 47.87%,
      #eb2428 91.79%
    );
  }
  .ConsultExpertH1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .ExpertContactBtn {
    border: 2px solid #fff !important;
    border-radius: 50%;
    color: #fff !important;
    padding: 0.5rem !important;
    font-size: 16px !important;
  }

  .AboutUsStudentImage {
    height: auto;
    width: 91%;
    border: 1rem solid #fff;
  }

  .aboutus-topdiv,
  .aboutus-bottomdiv {
    position: relative;
    height: auto;
    margin: 20px 0;
  }
  .img-top-aboutus {
    position: inherit;
    top: 0;
    left: 0;
  }
  .content-top-aboutus {
    position: inherit;
    margin-left: 0% !important;
    margin-top: 0% !important;
  }

  .img-bottom-aboutus {
    position: inherit;
    margin-left: 0% !important;
    margin-top: 2% !important;
  }
  .content-bottom-aboutus {
    position: inherit;
    left: 0;
    margin-top: 0% !important;
    z-index: 0;
  }
  .CarouselContainer {
    height: 400px;
  }
  .BannerImage {
    width: 100%;
    height: 400px;
  }
  .BannerBtn {
    color: #fff;
    padding: 0.4rem 1.25rem;
    text-decoration: none;
    position: absolute;
    font-size: 16px;
    top: 50%;
    left: 5%;
    background-size: 200% auto;
    box-shadow: 0 0 20px #eee;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    cursor: pointer;
    display: inline-block;
    background: radial-gradient(
      110.62% 3146.67% at 104.38% 50%,
      #eb2428 0%,
      #94001e 100%
    );
    border-radius: 25px;
  }
  .CarouselContainer {
    background-color: #eee;
    height: 400px;
  }
  .WhatWeDoCard {
    box-shadow: -8px -9px 15px 5px #aaaaaa, 8px 9px 15px 5px #aaaaaa,
      20px -9px 15px 5px #aaaaaa !important;
    font-size: 16px;
    /* height: 400px !important; */
  }
  .OurServicesCardContent {
    width: 325px;
    height: 300px;
  }
}
@media only screen and (max-width: 800px) and (min-width: 600px) {
  .CountryScreenBannerTitle {
    top: 19%;
    left: 25%;
    font-size: 40px !important;
    font-weight: 700 !important;
    padding: 3px !important;
  }

  .BannerTitle {
    position: absolute;
    top: 25%;
    left: 5%;
    font-size: 28px;
    font-weight: 600;
    width: 60%;
    line-height: 2rem;
  }

  .BannerDescription {
    font-size: 18px;
    font-weight: 300;
    position: absolute;
    top: 40%;
    left: 5%;
    width: 60%;
    margin-bottom: 1rem;
  }
  .BannerImage {
    width: 100%;
    height: 700px;
  }
  .CarouselContainer {
    height: 700px;
  }
}
@media only screen and (max-width: 1362px) and (min-width: 800px) {
  .CountryScreenBannerTitle {
    top: 25%;
    font-size: 40px !important;
    font-weight: 700 !important;
  }

  .BannerTitle {
    position: absolute;
    top: 25%;
    left: 5%;
    font-size: 24px;
    font-weight: 600;
    width: 50%;
    line-height: 2rem;
  }

  .BannerDescription {
    font-size: 18px;
    font-weight: 300;
    position: absolute;
    top: 40%;
    left: 5%;
    width: 50%;
    margin-bottom: 1rem;
  }
  .BannerImage {
    width: 100%;
    height: 600px;
  }
  .CarouselContainer {
    height: 600px;
  }
}
@media only screen and (max-width: 1620px) and (min-width: 1362px) {
  .BannerTitle {
    position: absolute;
    top: 25%;
    left: 5%;
    font-size: 34px;
    font-weight: 600;
    width: 40%;
    line-height: 2rem;
  }

  .BannerDescription {
    font-size: 18px;
    font-weight: 300;
    position: absolute;
    top: 40%;
    left: 5%;
    width: 25%;
    margin-bottom: 1rem;
  }
  .BannerImage {
    width: 100%;
    height: 700px;
  }
  .CarouselContainer {
    height: 700px;
  }
}
